import React, { Component } from 'react';
import HTTP from './services/httpservice';
import ReactGA from 'react-ga';
import {analytics_tracking_ID} from "./components/constants";
import  {initAmplitude} from '../js/commons/amplitude.js';

initAmplitude();

ReactGA.initialize(analytics_tracking_ID(), {
    debug: true,
    titleCase: false
});


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeHolder: {}
        }
    }

    componentWillMount() {
        var self = this;
        HTTP
            .get('/languageJson')
            .then(function (response) {
                if(response){
                    response.CLIENT_ID = "Client ID";
                }
                self.setState({ placeHolder: response })
            });
    }
    render() {
        return (
            <div>
                {React.cloneElement(this.props.children, this.props, {
                    placeHolder: this.state.placeHolder ,
                    ReactGA,
                })}
            </div>
        );
    }
}

export default Main;
