import React from 'react';
import { chatUrl } from '../../../constants.js';
import { Environment } from '../../../constants';
import HTTP from '../../../../services/httpservice'

import { setAmplitudelogEvent } from '../../../../commons/amplitude';

export default class ButtonsForNotification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			notificationObject: this.props.notificationObject
		};
	}

	_onClickReleaseButton() {
		window.$('#notimodalRelease').modal('show');
	}
	_onClickCloseButton() {
		window.$('#notimodalClose').modal('show');
	}
	_onClickAcceptButton() {
		this.props.onClickAcceptButton();
	}
	_callGetNotificationUpdate(status, comments, resolutionChannel) {
		let updateNotificationObject = this.props.notificationObject;
		updateNotificationObject.comments = comments;
		updateNotificationObject.caseCloseChannel = resolutionChannel !== undefined ? resolutionChannel : '';
		updateNotificationObject.userId = this.props.userId == '' ? 'gtradmin' : this.props.userId;
		updateNotificationObject.userName = this.props.userName ? this.props.userName : 'GTR Admin';
		var requestjson = {
			updateNotificationObject,
			status,
			restrictTo: 'PREM',
			processor: 'WebProcessor'
		};

		var callApiNotification = this.props.callApiNotification;
		var showMessage = this.props.showMessage;
		let headers = {
			Environment,
			orgId: this.props.orgId,
			accept: 'application/json',
			'content-type': 'application/json'
		};
		HTTP.postJSONHeader('/case/v1', headers, requestjson).then(function(response) {
			if (response.success === 'false') {
				showMessage('Case has changed. Please refresh your screen');
			} else {
				callApiNotification(response.cases[0]);
			}
		});
	}
	openNewWindow(data, travelerName) {
		setAmplitudelogEvent('Chat Window', {
			category: 'PTC',
			action: 'Chat Button Clicked'
		});
		// document.getElementById("chatbutton").disabled = true;
		// setTimeout(function(){document.getElementById("chatbutton").disabled = false},10000);
		//event.preventDefault();
		this.setState({
			isChatButtonDisabled: true
		});
		setTimeout(() => this.setState({ isChatButtonDisabled: false }), 10000);
		let departureAirport = '';
		let arrivalAirport = '';
		let recordLocator = '';
		let source = '';
		let channel = '';
		if (typeof data.travelerReplyChannel === 'string') {
			channel = data.travelerReplyChannel.split('|')[0];
		}
		let communicationId = data.communicationId !== undefined ? data.communicationId : null;
		let userId = data.userId !== undefined ? data.userId : null;
		let userName = data.userName !== undefined ? data.userName : null;
		let clientId = data.clientId !== undefined ? data.clientId : null;
		let clientName = data.clientName !== undefined ? data.clientName : null;
		// let conversationId = conversationIdSplit[1]!==undefined ? conversationIdSplit[1]:null;
		let disruptionType = data.disruptionType !== undefined ? data.disruptionType : '';
		if (this.props.specificTD !== undefined) {
			departureAirport =
				this.props.specificTD.DepartureAirport !== undefined ? this.props.specificTD.DepartureAirport : '';
			arrivalAirport =
				this.props.specificTD.ArrivalAirport !== undefined ? this.props.specificTD.ArrivalAirport : '';
			recordLocator =
				this.props.specificTD.BookingInfo.RecordLocator !== undefined
					? this.props.specificTD.BookingInfo.RecordLocator
					: '';
			source =
				this.props.specificTD.BookingInfo.Source !== undefined
					? this.props.specificTD.BookingInfo.Source
					: this.props.specificTD.BookingInfo.AtlasGDS;
		}
		let headers = {
			"consumer": 'chat',
			"channel": channel,
			"clientId": clientId,
			"accept": 'application/json',
			"content-type": 'application/json'
		};
		let url =
			'/communications/' +
			this.props.notificationObject.knowmeId +
			'/' +
			this.props.notificationObject.communicationId +
			'/' +
			clientId +
			'/v1';
		var self = this;
		HTTP.get(url, headers).then(function(response) {
			if (response.success === 'true') {
				let conversation = [];
				let contactId = '';
				let locale = response.locale !== undefined ? response.locale : null;
				if (response.communicationLogs !== undefined && response.communicationLogs.length > 0) {
					response.communicationLogs.map(function(item) {
						if (item.channel !== 'WEB') {
							if (item.channel === channel && item.messageFlow === 'I') {
								contactId = item.channelAddress;
							}
							let dateVal = item.insertedDateTime.substring(0, 10).split('/');
							let timeVal = item.insertedDateTime.substring(11, 19) + '.00';
							let dateTimeValue = dateVal[2] + '-' + dateVal[0] + '-' + dateVal[1] + ' ' + timeVal;
							let dataObj = {
								message: item.message !== undefined ? item.message : '',
								messageFlow: item.messageFlow !== undefined ? item.messageFlow : '',
								messageId: item.messageId !== undefined ? item.messageId : '12344',
								sentBy: item.insertedBy,
								sentDateTime: dateTimeValue,
								status: item.status
							};
							conversation.push(dataObj);
						}
					});
				}

				let url =
					chatUrl() +
					'?communicationId=' +
					communicationId +
					'&travelerName=' +
					travelerName +
					'&sentBy=' +
					userId +
					'&sentName=' +
					userName +
					'&clientId=' +
					clientId +
					'&clientName=' +
					clientName +
					'&contactId=' +
					contactId +
					'&conversation=' +
					JSON.stringify(conversation) +
					'&departureAirport=' +
					departureAirport +
					'&arrivalAirport=' +
					arrivalAirport +
					'&recordLocator=' +
					recordLocator +
					'&source=' +
					source +
					'&disruptionType=' +
					disruptionType +
					'&locale=' +
					locale;
				let randomnumber = Math.floor(Math.random() * 100 + 1);
				//window.open(url,"chatPopup"+recordLocator,'PopUp'+randomnumber+',toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resize=no,resizable=yes, width=400,height=400');
				self.props.openChatWindowMethod(url, recordLocator, randomnumber);
			}
		});
	}
	render() {
		let closeButton = (
			<button className="btn noti-seg-close-button" onClick={this._onClickCloseButton.bind(this)}>
				Close
			</button>
		);
		let releaseButton = (
			<button className="btn noti-seg-release-button" onClick={this._onClickReleaseButton.bind(this)}>
				Release
			</button>
		);
		let acceptButton = (
			<button className={this.props.notificationObject.status === 'PENDING' && this.props.notificationObject.isFlightSelected === 'Y' ? "btn noti-seg-accept-button flight-select-btn" : "btn noti-seg-accept-button"} onClick={this._onClickAcceptButton.bind(this)}>
				Accept
			</button>
		);
		let buttons = [];
		switch (this.props.notificationObject.status) {
			case 'INPROGRESS':
				buttons.push(closeButton);
				buttons.push(releaseButton);
				break;
			case 'PENDING':
				buttons.push(acceptButton);
				break;
			case 'CLOSED':
				buttons.push(<span className="notification_closed_text">Closed</span>);
				break;
		}
		let travelerName =
			this.props.notificationObject.travelerLName + ' ' + this.props.notificationObject.travelerFName;
		return (
			<span className="notification_segment_buttons">
				{buttons}
				{this.props.notificationObject.status === 'PENDING' && this.props.notificationObject.isFlightSelected === 'Y' ? <p><img src='../icons/flight_selected.png' className="flight-hlt-img"/><span className="flight-hlt">&nbsp; Flight Selected</span></p> : ''}
				{this.props.notificationObject.status === 'INPROGRESS' &&
				this.props.notificationObject.travelerReplyChannel !== undefined &&
				(this.props.notificationObject.travelerReplyChannel.includes('SMS') ||
				this.props.notificationObject.travelerReplyChannel.includes('MOBILE') ||
				this.props.notificationObject.travelerReplyChannel.includes('GCHAT') ||
				this.props.notificationObject.travelerReplyChannel.includes('ZOOM') ||
				this.props.notificationObject.travelerReplyChannel.includes('SLACK') ||
				this.props.notificationObject.travelerReplyChannel.includes('MSTEAMS') ||
				this.props.notificationObject.travelerReplyChannel.includes('WHATSAPP')) && 
				Environment === 'INTRANET' && (
					<button
						className="btn chat-button"
						id="chatbutton"
						disabled={this.state.isChatButtonDisabled}
						onClick={this.openNewWindow.bind(this, this.props.notificationObject, travelerName)}
					>
						Chat
					</button>
				)}
				{this.props.notificationObject.status === 'INPROGRESS' &&
				this.props.notificationObject.travelerReplyChannel !== undefined && 
				this.props.notificationObject.isFlightSelected === 'Y' ? <p className='flt-container'><img src='../icons/flight_selected.png' className="flight-hlt-img"/><span className="flight-hlt">&nbsp; Flight Selected</span></p> : ''}
			</span>
		);
	}
}
