'use strict';
import React from "react";
var ReactBootstrap = require("react-bootstrap");
import HTTP from '../../services/httpservice'

var GET_TRAVELER_QUERIES_URL = require('../../commons/apiUrls').GetTravelerQueriesURL;
import { Environment } from '../constants';
var OverlayTrigger = ReactBootstrap.OverlayTrigger;
import { Tooltip } from 'react-bootstrap';

export default class NotificationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationPendingSummary: [],
            notificationClosedSummary: [],
            notificationProgressSummary: [],
            notificationCount: 0,
            progressCount: 0,
            closedCount: 0,
            pendingCount: 0,
            loader: true,
            innersrchFlg: false,
            innerSrchTxt: "",
            isOverLayClicked: false,
            isPendingCallLoading: false,
            isInProgressCallLoading: false,
            isClosedCallLoading: false
        };
    }
    componentDidUpdate() {
        if (this.state.isOverLayClicked) {
            this
                .myPopover
                .hide();
            this.setState({ isOverLayClicked: false })
        }
    }
    componentDidMount() {
        var thisRef = this;
        // HTTP
        //     .get(GET_TRAVELER_QUERIES_URL + "all/"+ this.props.premGroup + "/" + this.props.userId + "/" + this.props.countryScope + "/v1").then(function (response) {
        //         if(response.count !== undefined) {
        //             if(response.count.inProgress>0) {
        //                 thisRef.setState({grantPtcAccessState:true})
        //             }
        //         } 
        //     });
        if (thisRef.props.userId == "")
            thisRef.props.userId = "gtradmin";
        this
            .props
            .socket
            .on('smsnotification', function (data) {
                var count = thisRef.state.notificationCount;
                thisRef.setState({
                    notificationCount: count + 1
                })
            });
            // HTTP
            //     .get(GET_TRAVELER_QUERIES_URL + "PENDING/" + thisRef.props.premGroup + "/" + thisRef.props.userId + "/" + thisRef.props.countryScope + "/v1", { Environment, "orgId": thisRef.props.restrict })
            //     .then(function (response) {
            //         thisRef.setState({ notificationCount: response.count.pending })
            //     });
            
    }
    _callGetTRavellerQueries(loader) {
        let self = this;
        self.getNotification("PENDING", "false", loader);
        self.getNotification("INPROGRESS", "false", loader);
        self.getNotification("CLOSED", "false", loader);
    }
    _updateNotificationPending(notificationPendingSummary, flag) {
        if (flag === "R" || flag === null) {
            console.log("Increasing notification summary");
            this.increaseCountbyOne(notificationPendingSummary);
        } else if (flag === "A") {
            console.log("Decreasing notification summary");
            this.decreaseCountbyOne(notificationPendingSummary);
        }
    }
    _callGetPendingTRavellerQueries(loader) {
        let self = this;
        self.getNotification("PENDING", "false", loader);
    }

    increaseCountbyOne(obj) {
        var pendingSummaryArr = this.state.notificationPendingSummary;
        pendingSummaryArr.push(obj)
        this.setState({
            notificationCount: this.state.notificationCount + 1,
            pendingCount: this.state.pendingCount + 1,
            notificationPendingSummary: pendingSummaryArr
        })
    }

    decreaseCountbyOne(obj) {
        var pendingSummaryArr = this.state.notificationPendingSummary;
        pendingSummaryArr = pendingSummaryArr.filter(function (el) {
            return el.caseId !== obj.caseId;
        });
        let notificationCount = this.state.notificationCount === 0 ? 0 : this.state.notificationCount - 1;
        let pendingCount = this.state.pendingCount === 0 ? 0 : this.state.pendingCount - 1;
        this.setState({
            notificationCount: notificationCount,
            pendingCount: pendingCount,
            notificationPendingSummary: pendingSummaryArr
        })
    }


    closeOverLay() {
        var self = this;
        self.setState({ isOverLayClicked: true })
    }
    getAllNotificationCounts() {
        if (!this.myPopover.state.show) {
            this.getNotification("all", "true");
        }
    }
    getNotification(Status, clearResult, loader = true) {
        let self = this;
        if (self.props.userId == "")
            self.props.userId = "gtradmin";
        this.setState({ loader: loader })
        if (Status === 'PENDING' || Status === 'all') {
            this.setState({ isPendingCallLoading: true })
        } else if (Status === 'INPROGRESS') {
            this.setState({ isInProgressCallLoading: true })
        } else {
            this.setState({ isClosedCallLoading: true })
        }
        if (clearResult == "true") {
            this.setState({ innerSrchTxt: "" })
        }
        HTTP
            .get(GET_TRAVELER_QUERIES_URL + Status + "/" + self.props.premGroup + "/" + self.props.userId + "/" + self.props.countryScope + "/v1", { Environment, "orgId": self.props.restrict })
            .then(function (response) {
                switch (Status) {
                    case "all":
                        let res = response;
                        response = response.cases != null ? self.checkFlightSelected(response.cases) : [];
                        self.setState({
                            notificationCount: res.count.pending,
                            notificationPendingSummary: response,
                            pendingCount: res.count.pending,
                            progressCount: res.count.inProgress,
                            closedCount: res.count.closed,
                            loader: false,
                            isPendingCallLoading: false
                        })
                        self
                            .props
                            .getNotificationList([
                                ...response,
                                ...self.state.notificationClosedSummary,
                                ...self.state.notificationProgressSummary
                            ])
                        break;
                    case "PENDING":
                        res = response;
                        response = response.cases != null
                            ?  self.checkFlightSelected(response.cases)
                            : []
                        self.setState({ notificationCount: res.count.pending, notificationPendingSummary: response, pendingCount: response.length, loader: false, isPendingCallLoading: false });
                        self
                            .props
                            .getNotificationList([
                                ...response,
                                ...self.state.notificationClosedSummary,
                                ...self.state.notificationProgressSummary
                            ])
                        break;
                    case "INPROGRESS":
                        response = response.cases != null
                            ?  self.checkFlightSelected(response.cases)
                            : []
                        self.setState({ notificationProgressSummary: response, progressCount: response.length, loader: false, isInProgressCallLoading: false });
                        self
                            .props
                            .getNotificationList([
                                ...self.state.notificationPendingSummary,
                                ...self.state.notificationClosedSummary,
                                ...response
                            ])
                        break;
                    case "CLOSED":
                        response = response.cases != null
                            ? self.checkFlightSelected(response.cases)
                            : [];
                        self.setState({ notificationClosedSummary: response, closedCount: response.length, loader: false, isClosedCallLoading: false });
                        self
                            .props
                            .getNotificationList([
                                ...self.state.notificationPendingSummary,
                                ...response,
                                ...self.state.notificationProgressSummary
                            ])
                        break;
                }
            });
    }
    _onKeyUp(event) {
        var val = event.target.value;
        if (val != "" || val != " ") {
            this.setState({ innerSrchTxt: val, innersrchFlg: true });
            if (document.getElementById('srchbox').value != "" && document.getElementById('srchbox').value != " ") {
                window.$('.background').show();
                window.$('.background span').text(val);
                window.$('.ptc_searchbar').val(val);
                // $('.ptc_searchbar').blur();
            }
            // else{   this.setState({     innerSrchTxt:"",     innersrchFlg:false
            //
            //   });
            //
            // }
        } else {
            this.setState({ innerSrchTxt: "", innersrchFlg: false });
        }
    }
    _onclicksrch() {
        var val = document
            .getElementById('srchbox')
            .value;
        if (val != "" && val != " ") {
            this.setState({ innerSrchTxt: val, innersrchFlg: true });
            window.$('.background').show();
            window.$('.background span').text(val);
            window.$('.ptc_searchbar').val(val);
        } else {
            this.setState({ innersrchFlg: false });
        }
    }
    _onFocus(event) {
        window.$(".searchBox-grey-active.background").css('display', 'none');
        //this.setState({innerSrchTxt:event.target.value,innersrchFlg:false});
    }
    _onChangeTxt(event) {
        var val = event.target.value;
        if (val != "" || val != " ") {
            this.setState({ innerSrchTxt: val, innersrchFlg: true });
            if (document.getElementById('srchbox').value != "" && document.getElementById('srchbox').value != " ") {
                window.$('.background').show();
                window.$('.background span').text(val);
                window.$('.ptc_searchbar').val(val);
                // $('.ptc_searchbar').blur();
            }
        } else {
            this.setState({ innerSrchTxt: "", innersrchFlg: false });
        }
    }
    // _onClose(){   this.setState({     innerSrchTxt:"",     innersrchFlg:false
    //
    //   });    $('.ptc_searchbar').val("");     $('.background').hide();
    //
    //   }

    // add new key flightSelect to the cases response to make search functionality work
    checkFlightSelected(summaryData) {
        for (let i = 0; i < summaryData.length; i++) {
            if (summaryData[i].isFlightSelected === 'Y') {
                summaryData[i].flightSelect = 'FLIGHT SELECTED';
            } else {
                summaryData[i].flightSelect = '';
            }
        }
        return summaryData;
    }

    render() {
        let pendingStatusJson = [];
        let closedStatusJson = [];
        let processStatusJson = [];
        var inPendingCount = 0;
        var inProgressCount = 0;
        var closeCount = 0;
        for (var i = 0; i < this.state.notificationPendingSummary.length; i++) {
            var fullName = this.state.notificationPendingSummary[i].travelerLName + " " + this.state.notificationPendingSummary[i].travelerFName;
            let scope = this.state.notificationPendingSummary[i].scope.split("_");
            let isFirst = false;
            if (scope[scope.length - 1] === 'PREM1' || scope[scope.length - 1] === 'PREM2' || scope[scope.length - 1] === 'PREM4') {
                isFirst = true;
            }
            if (this.state.innerSrchTxt.trim() != "") {
                if ((this.state.innerSrchTxt.trim().toLowerCase() == 'first' && isFirst) || 
                        this.state.notificationPendingSummary[i].recordLocator.toUpperCase().search(this.state.innerSrchTxt.toUpperCase()) != -1 || 
                        fullName.search(this.state.innerSrchTxt.trim().toUpperCase()) != -1 || 
                        this.state.notificationPendingSummary[i].flightNumber.search(this.state.innerSrchTxt.toUpperCase()) != -1 || 
                        this.state.notificationPendingSummary[i].clientId.search(this.state.innerSrchTxt.toUpperCase()) != -1 || 
                        (this.state.notificationPendingSummary[i].clientName !== undefined && this.state.notificationPendingSummary[i].clientName.trim().search(this.state.innerSrchTxt.toUpperCase()) != -1) ||
                        (this.state.notificationPendingSummary[i].flightSelect !== undefined && this.state.notificationPendingSummary[i].flightSelect.trim().search(this.state.innerSrchTxt.toUpperCase()) != -1)
                    ) {
                    pendingStatusJson.push(<NotificationComponentElement
                        closeOverLay={this
                            .closeOverLay
                            .bind(this)}
                        getTripSummaryByRecordLocator={this.props.getTripSummaryByRecordLocator}
                        TravelerQuery={this.state.notificationPendingSummary[i]}
                    />);
                    inPendingCount += 1;
                }
                if (inPendingCount == 0 && i == this.state.notificationPendingSummary.length - 1) {
                    pendingStatusJson.push(<NotificationComponentElement
                        closeOverLay={this
                            .closeOverLay
                            .bind(this)}
                    />);
                }
            } else {
                pendingStatusJson.push(<NotificationComponentElement
                    closeOverLay={this
                        .closeOverLay
                        .bind(this)}
                    getTripSummaryByRecordLocator={this.props.getTripSummaryByRecordLocator}
                    TravelerQuery={this.state.notificationPendingSummary[i]}
                />);
            }
        }
        for (var i = 0; i < this.state.notificationProgressSummary.length; i++) {
            var fullName = this.state.notificationProgressSummary[i].travelerLName + " " + this.state.notificationProgressSummary[i].travelerFName;
            let scope = this.state.notificationProgressSummary[i].scope.split("_");
            let isFirst = false;
            if (scope[scope.length - 1] === 'PREM1' || scope[scope.length - 1] === 'PREM2' || scope[scope.length - 1] === 'PREM4') {
                isFirst = true;
            }
            if (this.state.innerSrchTxt.trim() != "") {
                if ((this.state.innerSrchTxt.trim().toLowerCase() == 'first' && isFirst) || 
                this.state.notificationProgressSummary[i].recordLocator.toUpperCase().search(this.state.innerSrchTxt.toUpperCase()) != -1 || 
                fullName.search(this.state.innerSrchTxt.trim().toUpperCase()) != -1 || 
                this.state.notificationProgressSummary[i].flightNumber.search(this.state.innerSrchTxt.toUpperCase()) != -1 || 
                this.state.notificationProgressSummary[i].clientId.search(this.state.innerSrchTxt.toUpperCase()) != -1 || 
                (this.state.notificationProgressSummary[i].clientName !== undefined && this.state.notificationProgressSummary[i].clientName.trim().search(this.state.innerSrchTxt.toUpperCase()) != -1) || 
                (this.state.notificationProgressSummary[i].flightSelect !== undefined && this.state.notificationProgressSummary[i].flightSelect.trim().search(this.state.innerSrchTxt.toUpperCase()) != -1)) {
                    processStatusJson.push(<NotificationComponentElement
                        closeOverLay={this
                            .closeOverLay
                            .bind(this)}
                        getTripSummaryByRecordLocator={this.props.getTripSummaryByRecordLocator}
                        TravelerQuery={this.state.notificationProgressSummary[i]}
                    />);
                    inProgressCount += 1;
                }
                if (inProgressCount == 0 && i == this.state.notificationProgressSummary.length - 1) {
                    processStatusJson.push(<NotificationComponentElement
                        closeOverLay={this
                            .closeOverLay
                            .bind(this)}
                    />);
                }
            } else {
                processStatusJson.push(<NotificationComponentElement
                    closeOverLay={this
                        .closeOverLay
                        .bind(this)}
                    getTripSummaryByRecordLocator={this.props.getTripSummaryByRecordLocator}
                    TravelerQuery={this.state.notificationProgressSummary[i]}
                />);
            }
        }

        for (var i = 0; i < this.state.notificationClosedSummary.length; i++) {
            var fullName = this.state.notificationClosedSummary[i].travelerLName + " " + this.state.notificationClosedSummary[i].travelerFName;
            let scope = this.state.notificationClosedSummary[i].scope.split("_");
            let isFirst = false;
            if (scope[scope.length - 1] === 'PREM1' || scope[scope.length - 1] === 'PREM2' || scope[scope.length - 1] === 'PREM4') {
                isFirst = true;
            }
            if (this.state.innerSrchTxt.trim() != "") {
                if ((this.state.innerSrchTxt.trim().toLowerCase() == 'first' && isFirst) || this.state.notificationClosedSummary[i].recordLocator.toUpperCase().search(this.state.innerSrchTxt.toUpperCase()) != -1 || fullName.search(this.state.innerSrchTxt.trim().toUpperCase()) != -1 || this.state.notificationClosedSummary[i].flightNumber.search(this.state.innerSrchTxt.toUpperCase()) != -1 || this.state.notificationClosedSummary[i].clientId.search(this.state.innerSrchTxt.toUpperCase()) != -1 || ((this.state.notificationClosedSummary[i].clientName !== undefined && this.state.notificationClosedSummary[i].clientName.trim().search(this.state.innerSrchTxt.toUpperCase()) != -1)) || (this.state.notificationClosedSummary[i].flightSelect !== undefined && this.state.notificationClosedSummary[i].flightSelect.trim().search(this.state.innerSrchTxt.toUpperCase()) != -1)) {
                    closedStatusJson.push(<NotificationComponentElement
                        closeOverLay={this
                            .closeOverLay
                            .bind(this)}
                        getTripSummaryByRecordLocator={this.props.getTripSummaryByRecordLocator}
                        TravelerQuery={this.state.notificationClosedSummary[i]}
                    />);
                    closeCount += 1;
                }
                if (closeCount == 0 && i == this.state.notificationClosedSummary.length - 1) {
                    closedStatusJson.push(<NotificationComponentElement
                        closeOverLay={this
                            .closeOverLay
                            .bind(this)}
                    />);
                }
            } else {
                closedStatusJson.push(<NotificationComponentElement
                    closeOverLay={this
                        .closeOverLay
                        .bind(this)}
                    getTripSummaryByRecordLocator={this.props.getTripSummaryByRecordLocator}
                    TravelerQuery={this.state.notificationClosedSummary[i]}
                />);
            }
        }
        const popoverClickRootClose = (
            <div>
                <div
                    className='fade in popover bottom notification_pop_over_right notification_popup popover-notification_popup'>
                    <div className='arrow'></div>
                    <div className='notification-popover-content'>
                        <div className='popover_custom popover-custom-noti'>
                            <ul className="nav nav-tabs nav-tabs-custom">
                                <li className="tab active">
                                    <a
                                        href="#tab1"
                                        data-toggle="tab"
                                        style={{
                                            'border': '0px'
                                        }}
                                        onClick={this
                                            .getNotification
                                            .bind(this, "PENDING", "false")}>{this.props.placeHolder.PENDING}{this.state.isPendingCallLoading
                                                ? <span className="number-circle">&nbsp;</span>
                                                : <span className="number-circle">{this.state.pendingCount}</span>}</a>
                                </li>
                                <li className="tab">
                                    <a
                                        href="#tab2"
                                        data-toggle="tab"
                                        style={{
                                            'border': '0px'
                                        }}
                                        onClick={this
                                            .getNotification
                                            .bind(this, "INPROGRESS", "false")}>{this.props.placeHolder.IN_PROGRESS}{this.state.isInProgressCallLoading
                                                ? <span className="number-circle">&nbsp;</span>
                                                : <span className="number-circle">{this.state.progressCount}</span>}</a>
                                </li>
                                <li className="tab">
                                    <a
                                        href="#tab3"
                                        data-toggle="tab"
                                        style={{
                                            'border': '0px'
                                        }}
                                        onClick={this
                                            .getNotification
                                            .bind(this, "CLOSED", "false")}>{this.props.placeHolder.CLOSED}{this.state.isClosedCallLoading
                                                ? <span className="number-circle">&nbsp;</span>
                                                : <span className="number-circle">{this.state.closedCount}</span>}</a>
                                </li>
                            </ul>
                            <div className="popup"></div>
                            <div className="notification_search">
                                <input
                                    type="text"
                                    className="form-control ptc_searchbar"
                                    id="srchbox"
                                    tabIndex="-1"
                                    ref='srchBox'
                                    onChange={this
                                        ._onChangeTxt
                                        .bind(this)}
                                    onFocus={this
                                        ._onFocus
                                        .bind(this)}
                                    onClick={this
                                        ._onFocus
                                        .bind(this)}
                                    placeholder={this.props.placeHolder.PTC_SEARCH_TEXT} />
                                <span
                                    className="searchBoxPTC-grey-active background"
                                    id="input-grey-box"
                                    onClick={this
                                        ._onFocus
                                        .bind(this)}></span>
                                <hr />
                            </div>
                            <div className="tab-content" id="style1">
                                <div className="tab-pane active tab-panel-content" id="tab1">
                                    {this.state.loader
                                        ? <div className="loading_image notification_loader">&nbsp;</div>
                                        : pendingStatusJson}
                                </div>
                                <div className="tab-pane tab-panel-content" id="tab2">
                                    {this.state.loader
                                        ? <div className="loading_image notification_loader">&nbsp;</div>
                                        : processStatusJson}
                                </div>
                                <div className="tab-pane tab-panel-content" id="tab3">
                                    {this.state.loader
                                        ? <div className="loading_image notification_loader">&nbsp;</div>
                                        : closedStatusJson}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            //this.props.grantPtcAccess || this.state.grantPtcAccessState ? 
            <OverlayTrigger
                trigger="click"
                ref={(OverlayTrigger) => {
                    this.myPopover = OverlayTrigger;
                }}
                onClick={this
                    .getAllNotificationCounts
                    .bind(this)}
                rootClose
                placement="bottom"
                overlay={popoverClickRootClose}>
                 <div className="notification_icon_button">
                    <a className="notification_icon">&nbsp;</a>
                    <span className="button__badge">{this.state.notificationCount}</span>
                </div>
            </OverlayTrigger>
            //:null
        );
    }
}

class NotificationComponentElement extends React.Component {
    _searchByRecordLocator(obj) {
        this
            .props
            .getTripSummaryByRecordLocator(obj.recordLocator, obj);
        this
            .props
            .closeOverLay();
    }
    render() {
        let isFirst = false;
        if (this.props.TravelerQuery && this.props.TravelerQuery.scope) {
            let scope = this.props.TravelerQuery.scope.split("_");
            if (scope[scope.length - 1] === 'PREM1' || scope[scope.length - 1] === 'PREM2' || scope[scope.length - 1] === 'PREM4') {
                isFirst = true;
            }
        }
        if (this.props.TravelerQuery != undefined) {
            if (this.props.TravelerQuery.disruptionType != undefined) {
                if (this.props.TravelerQuery.disruptionType.toUpperCase().indexOf("CANCELLATION") != -1)
                    var textPlaceholder = "cancellation";
                else
                    textPlaceholder = "schedule delay";
            }
            var agedHours = 0;
            var agedMinutes = "";
            if (this.props.TravelerQuery.agedInMinutes != undefined) {
                if (this.props.TravelerQuery.agedInMinutes < 60) {
                    agedHours = 0;
                } else {
                    agedHours = Math.floor(this.props.TravelerQuery.agedInMinutes / 60);
                }
                agedMinutes = this.props.TravelerQuery.agedInMinutes % 60;
            }
            if (agedMinutes == 0)
                agedMinutes = "";
            else
                agedMinutes = ", " + agedMinutes + " minutes"
            var FlightData = "";
            if (this.props.TravelerQuery.flightNumber != undefined) {
                FlightData = this.props.TravelerQuery.flightNumber;
            }
            var clientName = "";
            if (this.props.TravelerQuery.clientName != undefined) {
                clientName = this
                    .props
                    .TravelerQuery
                    .clientName
                    .trim() + ", ";
            }
        }
        let langAlt = "";
        let langSource = "";
        let langName = "";
        if (this.props.TravelerQuery !== undefined && this.props.TravelerQuery !== null && this.props.TravelerQuery !== '' &&
            this.props.TravelerQuery.replyLangCode !== undefined && this.props.TravelerQuery.replyLangCode !== null && this.props.TravelerQuery.replyLangCode !== '') {
            let replyLanguage = this.props.TravelerQuery.replyLangCode;
            if (replyLanguage === "EN-US") {
                langAlt = replyLanguage;
                langName = "English";
                langSource = "/icons/EN.png";
            } else if (replyLanguage === "FR-FR") {
                langAlt = replyLanguage;
                langName = "French";
                langSource = "/icons/FR.png";
            } else if (replyLanguage === "DE-DE") {
                langAlt = replyLanguage;
                langName = "German";
                langSource = "/icons/DE.png";
            } else if (replyLanguage === "ES-ES") {
                langAlt = replyLanguage;
                langName = "Spanish";
                langSource = "/icons/ES.png";
            } else if (replyLanguage === "FR-CA") {
                langAlt = replyLanguage;
                langName = "FR Canadian";
                langSource = "/icons/CA.png";
            } else if (replyLanguage === "SE-SE") {
                langAlt = replyLanguage;
                langName = "Swedish";
                langSource = "/icons/SE.png";
            }
        }
        return (
            <div>
                {this.props.TravelerQuery != undefined
                    ? <div
                        className="noti-content"
                        onClick={this
                            ._searchByRecordLocator
                            .bind(this, this.props.TravelerQuery)}>
                        <h4 className="noti-client-name" style={{ display: "inline" }}>{this.props.TravelerQuery.travelerLName + " " + this.props.TravelerQuery.travelerFName}</h4>
                        {Environment === "INTRANET" && this.props.TravelerQuery.replyLangCode !== undefined && this.props.TravelerQuery.replyLangCode !== null && this.props.TravelerQuery.replyLangCode !== "" ?
                            this.props.TravelerQuery.replyLangCode.length > 1
                                ?
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="lang-tooltip-bottom" className="show">
                                        {langName}
                                    </Tooltip>}>
                                    <img alt={langAlt} className="langIcon" src={langSource} />
                                </OverlayTrigger>
                                :
                                <img alt={langAlt} className="langIcon" src={langSource} />
                            : null}
                        {isFirst ? <div className="crown-icon" /> : null}
                        {this.props.TravelerQuery.isFlightSelected === 'Y' ? <span className="flight-hlt">&nbsp; Flight Selected</span> : ''}
                        <p className="client-name client-name-size">{clientName + "" + this.props.TravelerQuery.clientId}</p>
                        <p className="noti-text">Need help regarding {textPlaceholder} for Flight &nbsp;
                                <b>{FlightData}</b>
                        </p>
                        <p className="noti-time">{agedHours} hrs{agedMinutes} ago
                            </p>
                        <hr />
                    </div>
                    : <div className="alert alert-danger-notification" role="alert">
                        No results found
                    </div>
                }
            </div>
        );
    }
}