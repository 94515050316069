'use strict';
import React from 'react';
import ConfirmRelease from '../CommonModels/ConfirmRelease.js';
import AcceptReleaseBy from '../Models/AcceptRelease.js';
import HTTP from '../../../../services/httpservice'
import SucessMessage from '../../commons/SucessMessage';
import CaseInteractions from './CaseInteractions.js';
import ButtonsForNotification from '../TripsAndNotification/ButtonsForNotification';

export default class NotificationSegment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openCaseInt: true,
			casesInteractionsData: [],
			isChatButtonDisabled: false
		};
	}
	componentDidMount() {
		var self = this;
		if (
			this.props.notificationObject &&
			this.props.notificationObject.knowmeId !== undefined &&
			this.props.notificationObject.communicationId !== undefined &&
			this.props.notificationObject.travelerReplyChannel !== undefined
		) {
			let channel = this.props.notificationObject.travelerReplyChannel.split('|')[0];
			let headers = {
				"channel": channel,
			};
			const url = '/communications/' +
							this.props.notificationObject.knowmeId +
							'/' +
							this.props.notificationObject.communicationId +
							'/' +
							this.props.notificationObject.clientId +
							'/v1';
			HTTP.get(url, headers).then(function(response) {
				if (response.success === 'true') {
					self.setState({
						casesInteractionsData: response.communicationLogs
					});
				} else {
					self.setState({
						casesInteractionsData: []
					});
				}
			});
		} else {
			self.setState({
				casesInteractionsData: []
			});
		}
	}
	onButtonClick(status, comments, resolutionChannel) {
		var resChannel = resolutionChannel === 'Select' ? '' : resolutionChannel;
		this.refs.buttonsForNotification._callGetNotificationUpdate(status, comments, resChannel);
	}
	openCaseInteractions(status) {
		this.setState({ openCaseInt: !status });
	}
	showMessage(text) {
		var self = this;
		this.refs.sucessmessage.showSucessMessage(text, 'change_status_err_succes');
		setTimeout(function() {
			self.refs.sucessmessage.disableSucessMessage();
		}, 4000);
	}
	render() {
		var userIdinLower = '';
		if (this.props.notificationObject === undefined || this.props.notificationObject === null) {
			return null;
		}
		if (this.props.notificationObject && this.props.notificationObject.userId != undefined)
			userIdinLower = this.props.notificationObject.userId.toLowerCase();
		if (this.props.notificationObject && this.props.notificationObject.disruptionType != undefined) {
			if (this.props.notificationObject.disruptionType.toUpperCase().indexOf('CANCELLATION') != -1)
				var textPlaceholder = 'cancellation';
			else textPlaceholder = 'schedule delay';
		}
		var agedHours = 0;
		var agedMinutes = '';
		if (this.props.notificationObject.agedInMinutes != undefined) {
			if (this.props.notificationObject.agedInMinutes < 60) {
				agedHours = 0;
			} else {
				agedHours = Math.floor(this.props.notificationObject.agedInMinutes / 60);
			}
			agedMinutes = this.props.notificationObject.agedInMinutes % 60;
		}
		if (agedMinutes == 0) agedMinutes = '';
		else agedMinutes = ', ' + agedMinutes + ' minutes';
		var FlightData = '';
		if (this.props.notificationObject.flightNumber != undefined) {
			FlightData = this.props.notificationObject.flightNumber;
		}

		return (
			<div className="notification_segment_border">
				<div>
					<SucessMessage ref="sucessmessage" />
				</div>
				{this.props.notificationObject && this.props.notificationObject.disruptionType != undefined ? (
					<div className={this.props.outOfSync ? 'notification-segment-outofsync' : 'notification_segment'}>
						<span className="notification_segment_img">&nbsp;</span>
						{this.props.outOfSync ? (
							<span>
								<span className="outofsync-text">
									GTR unable to locate disrupted segment. Please refer to PNR.
								</span>{' '}
								<span>
									<b>{this.props.outOfSyncPnr}</b>
								</span>
								<br />
							</span>
						) : null}
						<span className={this.props.outOfSync ? 'notification_segment_heading_outofsync' : ''}>
							Need help regarding {textPlaceholder} for Flight &nbsp;
							<b>{FlightData}</b>
						</span>
						{this.props.notificationObject.userId != null &&
						userIdinLower != this.props.userId &&
						this.props.notificationObject.status == 'INPROGRESS' ? null : (
							<ButtonsForNotification
								openChatWindowMethod={this.props.openChatWindowMethod}
								openedChatList={this.props.openedChatList}
								showMessage={this.showMessage.bind(this)}
								userId={this.props.userId}
								userName={this.props.userName}
								callApiNotification={this.props.callApiNotification}
								onClickAcceptButton={this.onButtonClick.bind(this, 'ACCEPTED')}
								Segkey={this.props.Segkey}
								ref="buttonsForNotification"
								user={this.props.user}
								notificationObject={this.props.notificationObject}
								specificTD={this.props.specificTD}
							/>
						)}
						<br />
						<span className="notification_segment_time">
							{agedHours} hrs{agedMinutes} ago
						</span>
						<div className="case-int-arrow-block">
							<div
								className={this.state.openCaseInt ? 'trip row' : 'trip row open'}
								onClick={this.openCaseInteractions.bind(this, this.state.openCaseInt)}
							>
								<a className="caret-down-travel" tabindex="0">
									&nbsp;
								</a>
							</div>
						</div>
					</div>
				) : null}
				{this.props.notificationObject.userId != null ? (
					<AcceptReleaseBy
						outOfSync={this.props.outOfSync}
						placeHolder={this.props.placeHolder}
						status={this.props.notificationObject.status}
						userId={this.props.notificationObject.userId}
						userName={this.props.notificationObject.userName}
						agedHours={agedHours}
						agedMinutes={agedMinutes}
					/>
				) : null}
				{this.props.outOfSync && <div className="add-border" />}
				<ConfirmRelease
					outOfSync={this.props.outOfSync}
					placeHolder={this.props.placeHolder}
					onClickSave={this.onButtonClick.bind(this, 'RELEASED')}
					popup="Release"
					DispTripId={this.props.DispTripId}
					tripmodeclass={this.props.tripmodeclass}
					userProfileData={this.props.userProfileData}
					Segkey={this.props.Segkey}
					popupdata={this.props.popupdata}
					specificTD={this.props.specificTD}
					openedChatList={this.props.openedChatList}
				/>
				<ConfirmRelease
					outOfSync={this.props.outOfSync}
					placeHolder={this.props.placeHolder}
					onClickSave={this.onButtonClick.bind(this, 'CLOSED')}
					popup="close"
					DispTripId={this.props.DispTripId}
					tripmodeclass={this.props.tripmodeclass}
					userProfileData={this.props.userProfileData}
					Segkey={this.props.Segkey}
					popupdata={this.props.popupdata}
					specificTD={this.props.specificTD}
					openedChatList={this.props.openedChatList}
				/>

				{this.state.openCaseInt && (
					<CaseInteractions casesInteractionsData={this.state.casesInteractionsData} />
				)}
			</div>
		);
	}
}
